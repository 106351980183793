const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get, isEmpty } = require('lodash');
const { createPanel, addPanel } = require('./libs/panel');
const { hourFloor } = require('./libs/utils');

const NewsCardModel = Backbone.Model.extend({
  'initialize': function() {
    this.listenTo(this, 'change:guid', this.newGuid);
  },
  'newGuid': function() {
    console.log('>> GUID changed:', this.get('guid'));
    this.getNews();
  },
  'getNews': function() {
    const guid = this.get('guid');
    request({
      'url': `${window.loc}/article`,
      'method': 'GET',
      'qs': {
        'guid': guid,
        'w' : hourFloor()
      }
    }, function(err, res, body) {
      console.log('statusCode', res.statusCode);
      if (err)
        console.error(err);
      else{
        // console.log(body);
        const fsJSON = JSON.parse(body);
        // console.log(fsJSON);

        this.set('article', fsJSON);
        //    console.log(body);
      }
    }.bind(this));
  }

});

const NewsCardView = Backbone.View.extend({
  'initialize': function(options) {
    this.eventBus = options.eventBus;

    /* this.model.on('all', function(eventName) {
      console.log(`${eventName } was triggered!`);
    });*/

    this.model.bind('change:article', this.doRender, this);
    this.eventBus.on('showNews', this.showNewsPanel, this);
  },

  'template': _.template(`
  <div class="newsarticle">
        <div style="text-align: center;"><img src="<%= image %>"></div>
        <div class="mui-container" style="margin-bottom:  50px;">
        <div><h1><%= title %></h1></div>
        <div><%= html %></div>
        </div>
  </div>
        `),
  'showNewsPanel': function(guid, cb = null) {
    console.log('Showing news', guid);
    const prevGuid = this.model.get('guid');

    this.model.set('guid', guid);

    this.$newPanel = createPanel({ 'title':'News', 'divId':'newsP' }, cb);

    this.$el = addPanel(this.$newPanel);

    // this.$el.empty();
    this.$newPanel.show();

    if (prevGuid === guid)
      this.doRender();
  },
  'events': {
    'click .closebutton': 'doClick'
  },
  'doClick': function(d) {
    console.log('Do click', d);
    const id = get(d, 'currentTarget', '');
    console.log(id);
    // this.eventBus.trigger('showNews', id);
  },
  'doClose': function(d) {
    console.log('close??');
  },
  'doRender': function() {
    this.$el.empty();
    this.$el.html(this.template(this.model.get('article')));
  }

});

module.exports = { NewsCardModel, NewsCardView };

