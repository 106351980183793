const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const { partOfDay, toHour } = require('./libs/utils');

const GreetModel = Backbone.Model.extend({
  'initialize': function() {
    this.set({ 'date': new Date(), 'todaySegment': partOfDay(), 'atHome': false, 'atWork': false, 'locationName':'' });

    const delay = toHour();
    this.timerID = setTimeout(
      () => this.tick(),
      delay);
  },
  'tick': function() {
    this.set({ 'date': new Date(), 'todaySegment': partOfDay() });

    this.timerID = setTimeout(
      () => this.tick(),
      toHour()
    );
  }
});

const GreetView = Backbone.View.extend({

  'tagName': 'div',
  'initialize': function (options) {
    _.bindAll(this, 'render');
    this.eventBus = options.eventBus;
    this.location = options.location;

    this.model.bind('change', this.render, this);
    this.location.bind('change', this.updateLocation, this);

    this.$greet = $('#greet');

    this.homeIcon = '<span role="img" aria-label="Home">🏠</span>';
    this.workIcon = '<span role="img" aria-label="Home">🏢</span>';
    // this.initView();
    //  console.log(this.get('routeView'));
    // console.log(this);
    this.render();
  },
  'updateLocation': function(l) {
    console.log('>> Location has changed...');
    // console.log(JSON.stringify(l.changed));

    if (l.has('atHome')) {
      const location = l.toJSON();
//      console.log('location:', location);
      console.log('city', location.city);
      if (location.hasOwnProperty('atHome'))
        this.model.set({ 'atHome':location.atHome, 'atWork':location.atWork, 'locationName':location.city });
    }
    else
      console.log('>> No location yet');
  },
  'render': function() {
    console.info('>> Greet:Render');
    const todaySegment = this.model.get('todaySegment');
    const atHome = this.model.get('atHome');
    const atWork = this.model.get('atWork');
    const locationName = this.model.get('locationName') || '';

    console.log('>> model', todaySegment, this.model.get('date'));

    let icon = '';
    let place = '';

    if (atHome) {
      icon = this.homeIcon;
      place = 'At home';
    }
    if (atWork) {
      icon = this.workIcon;
      place = 'At work';
    }

    if (locationName !== '') {
      if (place !== '') place = `${place}, `;

      place = place + locationName;
    }

    const html = `<div class="mui-panel greetPanel mui--text-center">
                    <h2>${todaySegment}</h2>
                    <div>${icon} ${place}</div>                    
                  </div>`;

    this.$greet.empty().html(html);
  }

});

module.exports = { GreetModel, GreetView };
