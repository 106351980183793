/**
 *
 * User: Martin Donnelly
 * Date: 2018-03-29
 * Time: 21:43
 *
 */
const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const { get } = require('lodash');

const { FSCollection } = require('./libs/fsbits');
const FSItemView = Backbone.View.extend({
  'tagName': 'div',
  'className': 'itemRow mui--align-middle',
  'template': _.template(`
<img class='mui--align-middle' src="<%= icon %>" width="32px" height="32px" style="-webkit-filter: invert(100%);"/><span class="mui--text-dark mui--text-subhead"><%= title %></span></span>      `),
  'initialize': function() {
    this.render();
  },
  'attributes': function() {
    return {
      'data-section': this.model.get('section'),
      'data-query': this.model.get('query')
    };
  },

  'render': function() {
    // console.log(this.model.attributes);
    this.$el.html(this.template(this.model.attributes));
  }
});

const NearbyPlacesView = Backbone.View.extend({
  'id':'nearby',
  'className': '',
  'initialize': function(options) {
    this.renderOn = false;
    this.list = [
      { 'icon': 'https://ss3.4sqi.net/img/categories_v2/food/default_32.png', 'title':'Restaurants', 'section':'13000' },
      { 'icon': 'https://ss3.4sqi.net/img/categories_v2/food/coffeeshop_32.png', 'title':'Cafes', 'section':'13032' },
      { 'icon': 'https://ss3.4sqi.net/img/categories_v2/nightlife/default_32.png', 'title':'Bars', 'section':'13003,13050' },
      { 'icon': 'https://ss3.4sqi.net/img/categories_v2/food/diner_32.png', 'title':'Diner', 'section':'13049' },
      { 'icon': 'https://ss3.4sqi.net/img/categories_v2/food/mexican_32.png', 'title':'Mexican Restaurant', 'section':'13303' },
      { 'icon': 'https://ss3.4sqi.net/img/categories_v2/food/default_32.png', 'title':'Buffet', 'section':'13030' },
      { 'icon': 'https://ss3.4sqi.net/img/categories_v2/food/tapas_32.png', 'title':'Tapas', 'section':'13345,13347' },
      { 'icon': 'https://ss3.4sqi.net/img/categories_v2/food/deli_32.png', 'title':'Subways', 'query':'ab4bee40-d68a-012e-5619-003048cad9da' },
      { 'icon': 'https://ss3.4sqi.net/img/categories_v2/shops/food_grocery_32.png', 'title':'Supermarket', 'section':'17142,17069' },
    ];
    this.eventBus = options.eventBus;
    this.location = options.location;

    this.collection = new FSCollection(this.list);

    this.location.bind('change:llFixed', this.updateLocation, this);
    this.location.bind('change:atHome', this.atHome, this);

    this.$nearby = $('#nearby');

    this.eventBus.on('focused', this.focused, this);

    this.other = $('<div id="nearbyOther" class="cardLink"><i class="seemore fa fa-forward mui--align-middle " ></i> <span class="seemore mui--align-middle">Other locations</span></div>');
  },
  'events': {
    'click .itemRow': 'doClick',
    'click #nearbyOther': 'doMoreClick'
  },
  'updateLocation': function(l) {
    console.log('>> Nearby Location has changed...');

    if (!this.renderOn && l.has('atHome')) {
      this.renderOn = true;
      this.render();
    }
    else
      console.log('>> Nearby No location yet');
  },
  'render': function() {
    console.info('>> NearbyPlaces:Render');
    this.$el.empty();

    this.collection.each(function(item) {
      const fsView = new FSItemView({ 'model': item });
      this.$el.append(fsView.el);
      // this.$el.append(personView.el); // adding all the person objects.
    }, this);

    if (this.renderOn)
      this.$el.parent().show();
    else
      this.$el.parent().hide();
  },
  'doClick': function(d) {
    console.log('Do click', d);
    const section = get(d, 'currentTarget.dataset.section', '');
    console.log(section);
    const query = get(d, 'currentTarget.dataset.query', '');
    console.log(section);
    const llFixed = this.location.get('llFixed');
    const data = { llFixed, section, query, 'limit':30 };

    console.log('>> outgoing', data);

    this.eventBus.trigger('showNearbyList', data);
  }, 'atHome': function() {
    if (this.location.get('atHome'))
      this.$el.parent().hide();

    if (this.renderOn && !this.location.get('atHome'))
      this.$el.parent().show();
  }

});

module.exports = { NearbyPlacesView };
