const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const { get } = require('lodash');
const { reduceNearby } = require('./libs/reducers');
const { createPanel, addPanel } = require('./libs/panel');
const TimeFormat = require('hh-mm-ss');

const { FSCollection, FSItemView } = require('./libs/fsbits');

const fsCollection = new FSCollection();

const NearbyListModel = Backbone.Model.extend({
  'defaults' : function (obj) {
    // return a new object
    return {
      'update' : new Date().getTime()
    };
  }, 'initialize': function() {
    this.fsCollection = fsCollection;
    this.listenTo(this, 'change:update', this.onChange);
    this.listenTo(this, 'change:section change:query', this.onChangeSection);
  },
  'onChange': function() {
    console.log('NearbyList::onChange');
    console.log(this);
    this.getNearby();
  },
  'onChangeSection': function() {
    console.log('NearbyList::onChangeSection');
    console.log(this);
    this.getNearby(true);
  },
  'getNearby': _.throttle(function(force = false) {
    const llFixed = this.get('llFixed');

    const hour = parseInt((new Date()).getHours().toString(), 10);
    const section = this.get('section') || '';
    const query = this.get('query') || '';
    const limit = this.get('limit');
    const time = new Date().getTime() ;

    const soqval = section || query;

    const soqtype = section ? "s" : "q";

    const lastUpdate = time - (this.get('time') || 0);

    console.log('>> Nearby section:', hour, section);
    console.info('>> Nearby:request');
    console.log(`>> Nearby last fetch: ${lastUpdate} ago`);

    //  this.fsCollection.reset( null, { 'silent':true });

    if (lastUpdate > 120000 || force) {
      const _url = new URL(`${window.loc}/fsexplore/${llFixed.replace(',','/')}/${soqval}/${soqtype}/${limit}`);

      console.log(_url);

      fetch(_url)
        .then((res) => {
          return res.json();
        })
        .then((fsJSON) => {
          /*const groups = get(fsJSON, 'response.groups');

          const items = groups[0].items;
          const newItems = [];
          this.set('totalResults', get(fsJSON, 'response.totalResults'));
          this.set('fullLocation', get(fsJSON, 'response.headerFullLocation'));
          for(const item of items)
            newItems.push(reduceNearby(item));*/

          const items = get(fsJSON, 'results');
          const newItems = [];
          this.set('totalResults', items.length);
          for(const item of items)
            newItems.push(reduceNearby(item));


          this.fsCollection.reset(newItems);
          this.logUpdate();
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, 9000), 'logUpdate': function() {
    console.log('NearyList logging:');

    const time = new Date().getTime() ;

    this.set('time', time, { 'silent': true });

    this.timerID = setTimeout(
      () => this.tick(),
      3.6e+6 + 1000
    );
  },
  'tick': function() {
    console.log('Set update');
    this.set('update', new Date().getTime());
  }

});

const NearbyListView = Backbone.View.extend({
  'initialize': function(options) {
    this.eventBus = options.eventBus;

    _.bindAll(this, 'doClick');

    this.model.fsCollection.bind('reset', this.render, this);
    this.eventBus.on('showNearbyList', this.showNearbyListPanel, this);
  },
  'showNearbyListPanel': function(data) {
    console.log('Showing nearby list', data);

    const prevSection = this.model.get('section');
    const prevll = this.model.get('llFixed');
    const lastTime = this.model.get('last');
    const now = new Date().getTime();

    this.model.set(data);

    this.model.set('update', new Date().getTime());
    this.$newPanel = createPanel({ 'title':'Nearby', 'divId':'NearbyListP' });

    this.$el = addPanel(this.$newPanel);

    // this.$el.empty();
    this.$newPanel.show();

    // console.log(this.model);
    if (prevll === data.llFixed && prevSection === data.section)
      if (now > lastTime + (60 * 1000 * 60)) {
        this.model.set('update', now);
      }
      else {
        this.render();
      }
  }, 'events': {
    'click': 'doClick'

  }, 'doClick': function(d) {
    const self = this;
    console.log('Do click', d);
    const id = get(d, 'currentTarget.dataset.id', '');
    console.log(id);
    this.eventBus.trigger('showVenueDetail', id, () => {
      console.log('News item panel closed');
      self.$newPanel.show();
    });
    this.$newPanel.hide();
  },
  'render' : function() {
    console.log('>> Do render');

    console.info('>> Nearby:Render');
    const totalResults = this.model.get('totalResults');
    console.log('>> totalResults', totalResults);
    this.$el.empty();

    const fullLocation = this.model.get('fullLocation');

    console.log('nearby::fullLocation', fullLocation);

    this.$el.append(`<div class="mui--text-subhead mui--text-accent">Near ${fullLocation}</div>`);

    this.model.fsCollection.each(function(item) {
      const fsView = new FSItemView({ 'model': item });
      this.$el.append(fsView.el);
      // this.$el.append(personView.el); // adding all the person objects.
    }, this);

    this.$el.append(this.other);

    this.$el.find('.itemRow').on('click', this.doClick);
  }

});

module.exports = { NearbyListModel, NearbyListView };
