const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get, isEmpty } = require('lodash');
const { maybePluralize, hourFloor } = require('./libs/utils');
const templates = require('./libs/templates');

const ByMeModel = Backbone.Model.extend({
  'initialize': function() {
    this.timerID = 0;
    // this.fsCollection = fsCollection;
    this.listenTo(this, 'change:llSix', this.onChange);
    this.listenTo(this, 'change:moving', this.onMoving);
  },
  'onMoving': function() {
    console.log('>> onMoving', this);
  },
  'onChange': function() {
    // this.getByMe(); trigger when not moved for 30 seconds
    console.log('>> changedAttributes:', this.changedAttributes());

    // Disabled...
    /*if (this.get('atHomeOrWork') !== true) {
      if ( this.timerID === 0) {
        console.log('setting timeout....');
        clearInterval(this.timerID);
        this.timerID = setTimeout(
          () => this.getByMe(),
          30000
        );
      }
    }
    else {
      clearInterval(this.timerID);
      console.log('>> ByMe: atHomeOrWork');
    }

     */
  },
  'getByMe': function() {
    const ll = this.get('ll');

    console.info('>> ByMe:request');

    clearInterval(this.timerID);
    this.timerID = 0;

    //  console.log(this.toJSON());
    // const section = (partOfDay >= 11 && partOfDay <= 14) ? 'food' : 'topPicks';
    request({
      'url': `${window.loc}/rightbyme/${ll.replace(',','/')}`,
      'method': 'GET',
    }, function(err, res, body) {
      console.log('statusCode', res.statusCode);
      if (err)
        console.error(err);
      else {
        // console.log(body);

        let fsJSON;
        try {
          fsJSON = JSON.parse(body);
        }
        catch(err) {
          console.error(err);
          console.log(body);
        }

        // console.log(fsJSON);

        this.set('byme', fsJSON);
      }
    }.bind(this));
  }
});

const ByMeView = Backbone.View.extend({
  'id':'byme',
  'className': '',
  'template': _.template('<div></div>'),

  'initialize': function(options) {
    this.eventBus = options.eventBus;
    this.location = options.location;

    // this.model.bind('change', this.render, this);
    this.location.bind('change:llSix', this.updateLocation, this);
    this.location.bind('change:atHomeOrWork', this.atHomeOrWork, this);
    this.model.bind('change:byme', this.render, this);
  },
  'events': {
    'click .itemRow': 'doClick'
  },
  'atHomeOrWork': function(m) {
    const atHomeOrWork = m.get('atHomeOrWork');
    console.log('>> atHomeOrWork', atHomeOrWork);
    if (atHomeOrWork)
    // We're at home so hide
      this.$el.parent().hide();
  },
  'updateLocation': function(l) {
    console.log('>> ByMe Location has changed...');

    if (l.has('atHome')) {
      const llSix = l.get('llSix');
      const ll = l.get('ll');
      const atHomeOrWork = l.get('atHomeOrWork');
      this.model.set({ llSix, atHomeOrWork, ll });
      if (atHomeOrWork)
      // We're at home so hide
        this.$el.parent().hide();
    }

    else
      console.log('>> ByMe No location yet');
  },
  'render': function() {
    console.info('>> ByMe:render');
    // console.log(this.model);

    // console.log(this.model);
    const contents = [];
    const m = this.model.get('byme');
    // this.imagesTemplate(this.model.toJSON()), this.yelpTemplate(this.model.toJSON()), this.tipsTemplate(this.model.toJSON())

    if (!isEmpty(m.images))
      contents.push(templates.imagesTemplate(m));

    if (!isEmpty(m.yelp))
      contents.push(templates.yelpTemplate(m));

    if (!isEmpty(m.tips))
      contents.push(templates.tipsTemplate(m));

    if (!isEmpty(m.tweets))
      contents.push(templates.tweetsTemplate(m));

    this.$el.html(contents.join(''));
    // console.log(this.el);
    this.$el.parent().children('#byMeTitle').html(m.name);
    this.$el.parent().show();
  }

});

module.exports = { ByMeModel, ByMeView };
