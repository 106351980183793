const $ = require('jquery');
let panelCount = 0;

function createPanel(params, cb = null) {
  const { title, divId } = params;
  const newPanel = `
    <div class="appPanel" data-id="${divId}">
  <div id="card_${divId}" class="fullscreen" style="display:;">
  <header class="header">
      <div class="mui-appbar mui--appbar-line-height mui--z1" style="vertical-align:middle;">
           <span>
            <button class="mui-btn mui-btn--large mui-btn--primary mui-btn--flat closebutton" id="close_${divId}">
                <i class="small fa fa-back mui--align-middle" style="color:black;"></i>
            </button>
        
        </span>
        <span class="mui--text-title mui--align-middle" id="title_${divId}">
            ${title}
        </span>
      </div>
  </header>
    
  <div class="box">
  
    <div class="content mui-panel" id="${divId}">
    <div class="showbox">
      <div class="loader">
        <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
        </svg>
      </div>
    </div>
    
</div>
  </div>
   
 <!-- end fullscreen-->   
  </div>
          </div>
  `;

  const $newPanel = $(newPanel);
  const $closeButton = $newPanel.find(`button#close_${divId}`);

  $closeButton.on('click', () => {
    doClose();
  });
  $newPanel.offset({ 'top': $(window).scrollTop(), 'left': 0 });

  function doClose() {
    const $body = $('body');
    $newPanel.hide().remove();
    console.log('panelCount', panelCount);
    panelCount--;
    if (panelCount === 0) {
      console.log('Removing panel stuff');
      $body.removeClass('stop-scrolling').unbind('touchmove');
    }

    if (cb !== null) {
      console.log('trying close cb');
      cb();
    }
  }

  return $newPanel;
}

function addPanel($newPanel) {
  const $body = $('body');
  const $content = $newPanel.find('.content');
  // $body.append($newPanel);
  $body.prepend($newPanel);
  if (panelCount === 0) {
    $body.addClass('stop-scrolling');
    $body.bind('touchmove', function(e) {
      e.preventDefault();
    });
  }
  panelCount++;

  return $content;
}

module.exports = { createPanel, addPanel };

/*
class Template {
  constructor(item) {
    // "pubdate": "Tue, 06 Feb 2018 17:05:00 +0100",
    const pubdateSrc = fecha.parse(item.pubdate, 'ddd, DD MMM YYYY HH:mm:SS ZZ');
    const pubdate = fecha.format(pubdateSrc, 'dddd MMMM Do, YYYY');
    const description = item.description.replace(/(<script(\s|\S)*?<\/script>)|(<style(\s|\S)*?<\/style>)|(<!--(\s|\S)*?-->)|(<\/?(\s|\S)*?>)/g, '');
    this.data = `<article>
<header>
<a href="${item.guid.text}">${item.title}</a>
<time class="published">${pubdate}</time>
</header>
<p class="description">${description}</p>
</article>`;
  }

  toString() {
    return this.data;
  }
}

 */
