const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get } = require('lodash');
const { reduceNearby } = require('./libs/reducers');
const { partOfDay } = require('./libs/utils');

const FSDetailView = Backbone.View.extend({
  'initialize': function(options) {
    this.eventBus = options.eventBus;
    this.fsID = options.fsID;

    console.log('New FSDetail View');
  }

});

module.exports = { FSDetailView };
