const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const fecha = require('fecha');
const TimeFormat = require('hh-mm-ss');
const { get } = require('lodash');
const { reduceOpenWeather } = require('./libs/reducers');
const { distance, toHour, hourFloor } = require('./libs/utils');

const WeatherAlertModel = Backbone.Model.extend({
  'defaults': function (obj) {
    // return a new object
    return {
      'update': new Date().getTime()
    };
  },
  'initialize': function () {
    this.run = false;
    this.listenTo(this, 'change:ll', this.onChange);
    this.listenTo(this, 'change:update', this.onChange);
  },
  'onChange': function (l) {
    console.log('WeatherAlert LL has changed');
    // if distance change > 10km
    // if its been an hour since last update
    const doUpdate = _.has(this.changedAttributes(), 'update');
    console.log('doUpdate?', doUpdate);

    /*
        console.log(m.changedAttributes());
    console.log(_.keys(m.changedAttributes()));
    _.has()

     */

    if (!this.has('log')) {
      console.info('First run');
      this.getWeatherAlert();
    }
    else {
      const log = this.get('log');
      const timeDiff = new Date().getTime() - log.time;
      const ll = this.get('ll').split(',');

      const dist = distance(log.lat, log.long, ll[0], ll[1]);
      console.log('WeatherAlert distance:', dist);

      if ((dist > 5.0) && (timeDiff > 1.8e+6))
        this.getWeatherAlert();
      else if (timeDiff > 3.6e+6 || doUpdate) {
        console.log('WeatherAlert hourly update');
        this.getWeatherAlert();
      }
    }
  },
  'getWeatherAlert': function () {
    // const ll = this.get('llShort');
    console.log('>> WeatherAlert request');
    const llFixed = this.get('ll');
    request({
      'url': `${window.loc}/weatheralert/${llFixed.replace(',','/')}`,
      'method': 'GET',
    }, function (err, res, body) {
      console.log('statusCode', res.statusCode);
      if (err)
        console.error(err);
      else {
        // console.log(body);
        const fsJSON = JSON.parse(body);

        const alerts = get(fsJSON, 'alerts', []);
        const item = alerts.slice(0, 1);

        this.set('alert', item);

        console.log(fsJSON);

        this.logUpdate();
      }
    }.bind(this));
  },
  'logUpdate': function () {
    console.log('WeatherAlert logging:');

    const ll = this.get('ll').split(',');

    const log = { 'lat': ll[0], 'long': ll[1], 'time': new Date().getTime() };

    console.log('Next update in', TimeFormat.fromMs(toHour(), 'hh:mm:ss'));
    this.timerID = setTimeout(
      () => this.tick(),
      toHour(1000)
    );
    console.log('>>WeatherAlert log', log);
    this.set('log', log, { 'silent': true });
    // console.log(this);
  },
  'tick': function () {
    console.log('WeatherAlert:Force update', fecha.format(new Date(), 'mediumTime'));
    this.set('update', new Date().getTime());
  }

}
);

const WeatherAlertView = Backbone.View.extend({
  'className': 'mui--align-middle',

  'initialize': function(options) {
    this.eventBus = options.eventBus;
    this.location = options.location;

    this.$parent = this.$el.parent();
    this.$title = $('#weatherAlertTitle');

    // this.model.bind('change', this.render, this);
    this.location.bind('change:llFixed', this.updateLocation, this);
    this.model.bind('change:alert', this.render, this);
    this.eventBus.on('focused', this.focused, this);
  },
  'template': _.template(`
    <div>
    <div class="mui--text-body1  %>"><%=description%></div>
          
            <div class="mui--text-dark-secondary mui--text-caption mui--text-right">
            Expires <%= readdate %>
          </div>
          `),
  'attributes': function() {
    return {
      'class': 'mui--align-middle'
    };
  },
  'events': {
    'click': 'doClick'
  },
  'updateLocation': function(l) {
    console.log('>> WeatherAlert Location has changed...');
    console.log('changedAttributes:', this.location.changedAttributes());

    if (l.has('atHome')) {
      const llFixed = l.get('llFixed');
      this.model.set('ll', llFixed);
    }
    else
      console.log('>> Weather No location yet');
  },
  'render': function() {
    console.log('>> WeatherAlert:Render');

    this.$el.empty();

    const alert = this.model.get('alert');

    if (alert.length === 0)
      // nothing to see, hide the alert.
      this.$parent.hide();
    else {
      const fts = new Date(alert[0].expires * 1000);
      alert[0].readdate = fecha.format(fts, 'default');
      this.$title.html(alert[0].title);
      this.$el.html(this.template(alert[0]));
      this.$el.parent().show();
    }
  }, 'focused': function() {
    console.log('>> WeatherAlert received focus msg');
    if (!this.model.has('log')) {
      console.log('No log yet');

      return ;
    }
    const now = new Date().getTime();
    const log = this.model.get('log');
    const since = now - log.time;

    console.log(`WeatherAlert was last updated: ${TimeFormat.fromMs(since, 'hh:mm')} ago`);

    if (since > (60 * 1000 * 60) )
      this.model.set('update', now);
  }
});

module.exports = { WeatherAlertModel, WeatherAlertView };
