const fecha = require('fecha');
const strictUriEncode = require('strict-uri-encode');
const { get } = require('lodash');

function reduceDarksky(item, city) {
  // Openweather returns timestamps in seconds. Moment requires them in milliseconds.
  // Replaced Moment with Fecha.
  // Too many issues trying to get Moment packaged into the bundle.

  if (!item || item === null) return {};
  const currently = get(item, 'currently');
  const alerts = get(item, 'alerts', []);
  const fts = new Date(currently.time * 1000);

  const newItem = { 'timestamp': fts,
    'icon': `wi-wu-${currently.icon}`,
    'summary': currently.summary,
    'temp': parseInt(currently.temperature, 10),
    'feels': parseFloat(currently.apparentTemperature, 10),
    'datelong': fecha.format(fts, 'YYYY-MM-DDTHH:mm:ss.SSSZZ'),
    'readdate': fecha.format(fts, 'default'),
    'time': fts,
    'date': fecha.format(fts, 'D/M'),
    'day': fecha.format(fts, 'ddd'),
    'city': city
  };

  return {
    'item' : newItem,
    'alerts': (alerts.length === 0) ? alerts : alerts.slice(0, 1)

  };
}

function reduceOpenWeather(item, city) {
  // Openweather returns timestamps in seconds. Moment requires them in milliseconds.
  // Replaced Moment with Fecha.
  // Too many issues trying to get Moment packaged into the bundle.

  if (!item) return {};
  const fts = new Date(item.dt * 1000);
  const weatherBlock = item.weather[0];

  return {
    'timestamp': item.dt,
    'icon': `wi-owm-${weatherBlock.id}`,
    'summary': weatherBlock.description,
    'temp': parseInt(item.temp['day'], 10),
    'tempHigh': parseFloat(item.temp['max'], 10),
    'tempLow': parseFloat(item.temp['min'], 10),
    'datelong': fecha.format(fts, 'YYYY-MM-DDTHH:mm:ss.SSSZZ'),
    'readdate': fecha.format(fts, 'default'),
    'time': item.dt,
    'date': fecha.format(fts, 'D/M'),
    'day': fecha.format(fts, 'ddd'),
    'city': city

  };
}

function reduceOpenWeather5DayForecast(item, city) {
  // Openweather returns timestamps in seconds. Moment requires them in milliseconds.
  // Replaced Moment with Fecha.
  // Too many issues trying to get Moment packaged into the bundle.

  if (!item || item === null) return {};
  const fts = new Date(item.dt * 1000);
  const weatherBlock = item.weather[0];

  return {
    'timestamp': item.dt,
    'icon': `wi-owm-${weatherBlock.id}`,
    'summary': weatherBlock.description,
    'tempHigh': parseInt(item.temp.max, 10),
    'tempLow': parseInt(item.temp.min, 10),
    'datelong': fecha.format(fts, 'YYYY-MM-DDTHH:mm:ss.SSSZZ'),
    'time': item.dt,
    'date': fecha.format(fts, 'D/M'),
    'day': fecha.format(fts, 'ddd'),
    'city': city

  };
}

const OLDreduceNearby = function(item) {
  const obj = {};
  const categories = get(item, 'venue.categories');
  obj.id = get(item, 'venue.id', '');
  obj.name = get(item, 'venue.name', '');
  obj.category = get(categories[0], 'name', '');
  obj.icon = `${get(categories[0], 'icon.prefix', '')}32${get(categories[0], 'icon.suffix', '')}`;

  obj.distance = (get(item, 'venue.location.distance', 0) / 1000).toFixed(2);

  return obj;
};

/* Updated to use new 2024 fsq */
const reduceNearby = function(item) {
  const obj = {};
  const categories = get(item, 'categories');
  obj.id = get(item, 'fsq_id', '');
  obj.name = get(item, 'name', '');
  obj.category = get(categories[0], 'name', '');
  obj.icon = `${get(categories[0], 'icon.prefix', '')}32${get(categories[0], 'icon.suffix', '')}`;

  obj.distance = (get(item, 'distance', 0) / 1000).toFixed(2);

  return obj;
};

const reduceEuronews = function(item) {
  const obj = {};

  const pubdateSrc = fecha.parse(item.pubdate, 'ddd, DD MMM YYYY HH:mm:SS ZZ');
  obj.pubdate = fecha.format(pubdateSrc, 'dddd MMMM Do, YYYY HH:mm');
  obj.description = item.description.replace(/(<script(\s|\S)*?<\/script>)|(<style(\s|\S)*?<\/style>)|(<!--(\s|\S)*?-->)|(<\/?(\s|\S)*?>)/g, '');

  if (obj.description === '1')
    obj.description = '';

  obj.guid = encodeURI(item.guid.text);
  obj.title = item.title;

  console.log(obj);

  return obj;
};

const reduceForcastToday = function(listArray) {

  const newArray = listArray.map((item) => {

    const fts = new Date(item.time * 1000);

    return {
      'icon': item.icon,
      'temp': item.temp,
      'time': fecha.format(fts, 'HH:mm')
    };
  });

  return newArray.slice(0,24);
}
const reduceForcastDaily = function(listArray) {

  return listArray.map((item) => {

    const fts = new Date(item.time * 1000);

    return {
      'icon': item.icon,
      'tempHigh': item.tempHigh,
      'tempLow': item.tempLow,
      'time': fecha.format(fts, 'dddd')
    };
  });



}
/*
const pubdateSrc = fecha.parse(item.pubdate, 'ddd, DD MMM YYYY HH:mm:SS ZZ');
    const pubdate = fecha.format(pubdateSrc, 'dddd MMMM Do, YYYY');
    const description = item.description.replace(/(<script(\s|\S)*?<\/script>)|(<style(\s|\S)*?<\/style>)|(<!--(\s|\S)*?-->)|(<\/?(\s|\S)*?>)/g, '');
    this.data = `<article>
<header>
<a href="${item.guid.text}">${item.title}</a>
<time class="published">${pubdate}</time>
</header>
<p class="description">${description}</p>
</article>`;
 */

module.exports = { reduceOpenWeather, reduceNearby, reduceEuronews, reduceDarksky, reduceForcastToday, reduceForcastDaily };
