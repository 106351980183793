//
const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');

const { toHour, hourFloor } = require('./libs/utils');
const TimeFormat = require('hh-mm-ss');
const fecha = require('fecha');

const AgendaItem = Backbone.Model.extend({

});

const AgendaCollection = Backbone.Collection.extend({
  'model': AgendaItem
});

const agendaCollection = new AgendaCollection();

const AgendaItemView = Backbone.View.extend({
  'tagName': 'div',
  'className': 'itemRow mui-row',
  'template': _.template(`
<div class="mui-col-xs-3 mui--text-caption mui--text-dark-secondary mui--align-middle"><%= readDate %></div>
<div class="mui-col-xs-9 mui--text-dark mui--text-subhead mui--align-middle"><%= summary %></div>
`),
  'initialize': function() {
    this.render();
  },
  'attributes': function() {
    return {

      /* 'data-id': this.model.id*/
    };
  },

  'render': function() {
    this.$el.html(this.template(this.model.toJSON()));
  }
});

const AgendaModel = Backbone.Model.extend({
  'defaults' : function (obj) {
    // return a new object
    return {
      'update' : new Date().getTime()
    };
  }, 'initialize': function() {
    this.agendaCollection = agendaCollection;
    this.timerID = 0;
    this.tick();
    this.set('totalResults', 0);
    this.listenTo(this, 'change:update', this.getAgenda);
    this.getAgenda();
  },
  'tick': function() {
    this.timerID = setTimeout(
      () => this.tick(),
      toHour()
    );
  },
  'onChange': function() {
    this.getAgenda();
  },
  'getAgenda': function() {
    const time = new Date().getTime() ;

    console.log('>> agenda this', this);

    const lastUpdate = time - (this.get('time') || 0);

    console.log(`>> Agenda last fetch: ${TimeFormat.fromMs(lastUpdate, 'hh:mm')} ago`);

    if (lastUpdate > 120000) {
      const _url = new URL(`${window.loc}/agenda`);

      _url.search = new URLSearchParams({
        'w' : hourFloor()
      });

      console.log(_url);

      fetch(_url)
        .then((res) => {
          return res.json();
        })
        .then((agendaJSON) => {
          const now = new Date().getTime();
          const newAgenda = [];

          // console.log('>> agendaJSON', agendaJSON);
          for(const item of agendaJSON) {
            //

            const dend = new Date(item.dtend).getTime();

            if (dend > now) {
              //    console.log('>> agenda', item);
              item.timeStart = fecha.format(new Date(item.dtstart), 'shortTime');
              newAgenda.push(item);
            }

            // console.log(dend);
          }

          this.agendaCollection.reset(newAgenda);
          const time = new Date().getTime() ;

          this.set('time', time);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, 'logUpdate': () => {
    console.log('>> Agenda logging:');

    console.log('>> agend log update', this);

    const time = new Date().getTime() ;

    this.set('time', time, {silent: true});
  }

});

const AgendaView = Backbone.View.extend({
  'id':'agenda',
  'className': '',
  'initialize': function(options) {
    this.eventBus = options.eventBus;

    this.model.agendaCollection.bind('reset', this.render, this);
    this.eventBus.on('focused', this.focused, this);
  }, 'focused': function() {
    console.log('>> Agenda received focus msg');
    const now = new Date().getTime();
    const since = now - this.model.get('time');

    console.log('> t', now, this.model.get('time'));

    console.log(`Agenda was last updated: ${TimeFormat.fromMs(since, 'hh:mm')} ago`);

    if (since > (60 * 1000 * 60) )
      this.model.set('update', now);
  },
  'render': function() {
    console.info('>> Agenda:Render');

    //   console.log('>> Agenda', this.model.agendaCollection);
    this.$el.empty();

    if (this.model.agendaCollection.length === 0)
      this.$el.parent().hide();
    else
      this.model.agendaCollection.each(function(item) {
        const aView = new AgendaItemView({ 'model': item });
        this.$el.append(aView.el);
        this.$el.parent().show();
        // this.$el.append(personView.el); // adding all the person objects.
      }, this);
  }

});

module.exports = { AgendaModel, AgendaView };
