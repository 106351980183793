const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get } = require('lodash');
const { reduceEuronews } = require('./libs/reducers');
const { createPanel, addPanel } = require('./libs/panel');
const { hourFloor } = require('./libs/utils');

const NewsItem = Backbone.Model.extend({

});

const NewsCollection = Backbone.Collection.extend({
  'model': NewsItem
});

const newsCollection = new NewsCollection();

const NewsItemView = Backbone.View.extend({
  'tagName': 'div',
  'className' : 'newsItem',
  'template': _.template(`
    
    <div class="mui--text-subhead mui--text-accent"><%=title%></div>
    <div class="published mui--text-dark-secondary mui--text-caption"><%=pubdate%></div>
   
    <p class="mui--text-body1"><%=description%></p>

          `),
  'initialize': function() {
    this.render();
  },
  'attributes': function() {
    return {
      'data-guid': this.model.get('guid')
    };
  },

  'render': function() {
    this.$el.html(this.template(this.model.toJSON()));
  }
});

const NewsListModel = Backbone.Model.extend({
  'defaults' : function (obj) {
    // return a new object
    return {
      'update' : new Date().getTime()
    };
  }, 'initialize': function() {
    this.newsCollection = newsCollection;
    this.listenTo(this, 'change:update', this.onChange);
    this.getNews();
  },
  'onChange': function() {
    this.getNews();
  },
  'getNews': function() {
    console.info('>> NewsList:getNews');
    request({
      'url': `${window.loc}/news`,
      'method': 'GET', 'qs': {

        'w' : hourFloor()
      }
    }, function(err, res, body) {
      if (err)
        console.error(err);
      else {
        console.log('statusCode', res.statusCode);
        const fsJSON = JSON.parse(body);
        // console.log(body);
        const newItems = [];

        for (const item of fsJSON.items)
          newItems.push(reduceEuronews(item));

        this.newsCollection.reset(newItems);

        this.logUpdate();
      }
    }.bind(this));
  }, 'logUpdate': function() {
    console.log('News logging:');

    const time = new Date().getTime() ;

    this.set('time', time, {silent: true});

    this.timerID = setTimeout(
      () => this.tick(),
      3.6e+6 + 1000
    );
  },
  'tick': function() {
    console.log('Set update');
    this.set('update', new Date().getTime());
  }

});

const NewsListView = Backbone.View.extend({
  'initialize': function(options) {
    this.eventBus = options.eventBus;

    _.bindAll(this, 'doClick');

    this.model.newsCollection.bind('reset', this.render, this);
    this.eventBus.on('showNewsList', this.showNewsListPanel, this);
  },
  'showNewsListPanel': function() {
    console.log('Showing news list');

    this.model.set('update', new Date().getTime());
    this.$newPanel = createPanel({ 'title':'More news', 'divId':'newsListP' });

    this.$el = addPanel(this.$newPanel);

    // this.$el.empty();
    this.$newPanel.show();
  }, 'events': {
    'click': 'doClick'

  }, 'doClick': function(d) {
    //  console.log('Do click', d);
    const self = this;
    const id = get(d, 'currentTarget.dataset.guid', '');
    console.log(id);
    this.eventBus.trigger('showNews', id, () => {
      console.log('News item panel closed');
      this.$newPanel.show();
    });
    this.$newPanel.hide();
  },
  'render' : function() {
    console.log('>> Do render');

    this.$el.empty();

    this.model.newsCollection.each(function(item) {
      const niView = new NewsItemView({ 'model': item });

      this.$el.append(niView.el);
    }, this);

    this.$el.find('.newsItem').on('click', this.doClick);
  }

});

module.exports = { NewsListModel, NewsListView };
