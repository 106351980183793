/* eslint-disable max-len */
require('muicss');
const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');

const { LocationModel, LocationView } = require('./Location');
const { GreetModel, GreetView } = require('./Greet');
const { NearbyModel, NearbyView } = require('./Nearby');
const { WeatherModel, WeatherView } = require('./Weather');
const { WeatherAlertModel, WeatherAlertView } = require('./WeatherAlert');
const { ForecastModel, ForecastView } = require('./Forecast');
const { NewsModel, NewsView } = require('./News');
const { NewsCardModel, NewsCardView } = require('./NewsViewer');
const { NewsListModel, NewsListView } = require('./NewsList');
const { ByMeModel, ByMeView } = require('./RightByMe');
const { VenueDetailModel, VenueDetailView } = require('./VenueDetail');
const { AgendaModel, AgendaView } = require('./Agenda');
const { TrafficModel, TrafficView } = require('./Traffic');
const { NearbyListModel, NearbyListView } = require('./NearbyList');
const { NearbyPlacesView } = require('./NearbyPlaces');

const { GotoCardModel, GotoCardView } = require('./Goto');

var app = app || {};

const live = true;

if (live) {
  const url = new URL(window.location.origin);
  console.log('url', url);

  window.loc = url.origin;

  if ('serviceWorker' in navigator) {
    //
    navigator.serviceWorker.ready.then(function(reg) {
      console.warn('Ready??', reg);
      main();
    });

    window.addEventListener('load', function() {
      navigator.serviceWorker
        .register('./service-worker.js')
        .then(function(r) {
          console.warn('Service Worker Registered', r.scope);
        })
        .catch(function(error) {
          // registration failed
          console.error(`Registration failed with ${ error}`);
        });
    });

    //
  }
}
else {
  console.log('window.location', window.location.origin);

  const url = new URL(window.location.origin);
  console.log('url', url);

  window.loc = url.origin;
  main();
}

function main() {
  const offline = false;

  console.log('>>> START!!! <<<');

  /* var myRouter = Backbone.Router.extend({
    //

  });*/

  app.eventBus = _.extend({}, Backbone.Events);

  app.locationModel = new LocationModel();

  app.locationView = new LocationView({ 'model': app.locationModel, 'el':'#location' });

  // new TrainView({ 'model': new TrainModel(route), 'eventBus': app.eventBus });

  app.greetView = new GreetView({ 'model': new GreetModel(), 'eventBus': app.eventBus, 'location': app.locationModel });

  app.nearby = new NearbyView({ 'model': new NearbyModel(), 'eventBus': app.eventBus, 'location': app.locationModel, 'el':'#nearby' });

  app.weather = new WeatherView({ 'model': new WeatherModel(), 'eventBus': app.eventBus, 'location': app.locationModel, 'el':'#weather', 'viewFrame':'#viewFrame' });

  app.weatherAlert = new WeatherAlertView({ 'model': new WeatherAlertModel(), 'eventBus': app.eventBus, 'location': app.locationModel, 'el':'#weatherAlert', 'viewFrame':'#viewFrame' });

  app.forecastCard = new ForecastView({ 'model': new ForecastModel(), 'eventBus': app.eventBus });

  app.newsCard = new NewsCardView({ 'model': new NewsCardModel(), 'eventBus': app.eventBus });

  app.newsList = new NewsListView({ 'model': new NewsListModel(), 'eventBus' : app.eventBus });

  app.news = new NewsView({ 'model': new NewsModel(), 'eventBus': app.eventBus, 'el':'#newsShell' });

  app.byMe = new ByMeView({ 'model': new ByMeModel(), 'eventBus': app.eventBus, 'location': app.locationModel, 'el':'#byme' });

  app.agenda = new AgendaView({ 'model': new AgendaModel(), 'eventBus': app.eventBus, 'el':'#agenda' });

  app.traffic = new TrafficView({ 'model': new TrafficModel(), 'eventBus': app.eventBus, 'location': app.locationModel, 'el':'#traffic' });

  app.venueCard = new VenueDetailView({ 'model': new VenueDetailModel(), 'eventBus': app.eventBus, 'location': app.locationModel });

  app.nearbyList = new NearbyListView({ 'model': new NearbyListModel(), 'eventBus' : app.eventBus });

  app.nearbyPlacesView = new NearbyPlacesView({ 'eventBus': app.eventBus, 'location': app.locationModel, 'el':'#nearbyPlaces' });

  app.gotoCard = new GotoCardView({ 'model': new GotoCardModel(), 'eventBus': app.eventBus, 'location': app.locationModel });

  app.updateOnlineStatus = function(event) {
    if (navigator.onLine) {
      // handle online status
      console.log('online');
      $('#connectionStatus').hide(500);
    }

    else {
      // handle offline status
      console.log('offline');
      $('#connectionStatus').show(500);
    }
  };

  window.addEventListener('focus', () => {
    console.log('Window has focus...');
    app.eventBus.trigger('focused');
  });

  window.addEventListener('online', app.updateOnlineStatus);
  window.addEventListener('offline', app.updateOnlineStatus);
}
