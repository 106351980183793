const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get, isEmpty } = require('lodash');
const { createPanel, addPanel } = require('./libs/panel');
const L = require('leaflet');

const templates = require('./libs/templates');

const VenueDetailModel = Backbone.Model.extend({
  'initialize': function() {
    this.listenTo(this, 'change:id', this.newId);
  },
  'newId': function() {
    console.log('>> ID changed:', this.get('id'));
    this.getVenue();
  },
  'getVenue': function() {
    const id = this.get('id');
    request({
      'url': `${window.loc}/nearbydetail/${id}`,
      'method': 'GET',
      'qs': {
        'id': id
      }
    }, function(err, res, body) {
      console.log('statusCode', res.statusCode);
      if (err)
        console.error(err);
      else{
        // console.log(body);
        const fsJSON = JSON.parse(body);
        // console.log(fsJSON);

        this.set('details', fsJSON);
        //    console.log(body);
      }
    }.bind(this));
  }

});

const VenueDetailView = Backbone.View.extend({
  'initialize': function(options) {
    this.eventBus = options.eventBus;
    this.location = options.location;

    this.model.bind('change:details', this.doRender, this);
    this.eventBus.on('showVenueDetail', this.showVenuePanel, this);
  },

  'showVenuePanel': function(id, cb = null) {
    console.log('Showing venue details', id);
    const prevId = this.model.get('id');

    this.model.set('id', id);

    this.$newPanel = createPanel({ 'title':'Venue details', 'divId':'VenueDetail' }, cb);

    this.$el = addPanel(this.$newPanel);

    // this.$el.empty();
    this.$newPanel.show();

    if (prevId === id)
      this.doRender();
  },
  'events': {
    'click .closebutton': 'doClick',
    'click #getDirections': (d) => {
      console.log('Clicked', d);
    }
  },
  'doClick': function(d) {
    console.log('Do click', d);
    const id = get(d, 'currentTarget', '');
    console.log(id);
    // this.eventBus.trigger('showNews', id);
  },
  'doNavigate': function(d) {
  //  console.log('Do click', d);
    console.log('>> this.model', this);
    const details = this.model.get('details');
    console.log(details);
    this.eventBus.trigger('showDirections', details, () => {
      console.log('Navigate panel closed');
      this.$newPanel.show();
    });
    this.$newPanel.hide();
  },
  'doClose': function(d) {
    console.log('close??');
  },
  'doRender': function() {
    const contents = [];
    const m = this.model.get('details');
    // this.imagesTemplate(this.model.toJSON()), this.yelpTemplate(this.model.toJSON()), this.tipsTemplate(this.model.toJSON())

    //   console.log(this.model.attributes);
    contents.push(templates.venueTitle(m));

    const $map = $('#map');

    contents.push(templates.map());

    contents.push(templates.venueDirections(m));

    contents.push(templates.venueDescription(m));

    if (!isEmpty(m.yelp))
      contents.push(templates.yelpTemplate(m));

    if (!isEmpty(m.tips))
      contents.push(templates.tipsTemplate(m));

    if (!isEmpty(m.tweets))
      contents.push(templates.tweetsTemplate(m));

    if (!isEmpty(m.images))
      contents.push(templates.imagesTemplate(m));

    contents.push(templates.openInFS(m));
    this.$el.empty();
    this.$el.html(contents.join(''));
    this.$el.append($map);

    this.map = L.map('map', {
      'center': [m.latitude, m.longitude],
      'zoom': 15
    });

    // L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {

      'attribution': 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
      'maxZoom': 18,
      'id': 'mapbox.streets',
      'accessToken': 'pk.eyJ1IjoibWFydGluZDIwMDAiLCJhIjoiY2pmNnlnc3F1MGpoYzJ5bXpscGFwaTlueiJ9.sx1ToptfsUf5HF3-0VVC-Q'
    }).addTo(this.map);

    const mePoints = this.location.get('ll').split(',');
    const dest = new L.LatLng(m.latitude, m.longitude);
    const me = new L.LatLng(mePoints[0], mePoints[1]);

    const pointList = [dest, me];

    const firstpolyline = new L.Polyline(pointList, {
      'color': 'red',
      'weight': 3,
      'opacity': 0.5,
      'smoothFactor': 1
    });
    firstpolyline.addTo(this.map);

    L.marker(dest).addTo(this.map);

    /* var circle =*/
    L.circle(me, {
      'color': 'blue',
      'fillColor': '#00a6ff',
      'fillOpacity': 0.5,
      'opacity': 0.5,
      'radius': 30
    }).addTo(this.map);

    this.$el.find('#getDirections').on('click', () => {
      this.doNavigate();
    });
    // console.log(this.location.attributes);
  }

});

module.exports = { VenueDetailModel, VenueDetailView };

