const _ = require('underscore');

const templates = {

  'empty': _.template('<div></div>'),
  'yelpTemplate': _.template(`<div id="bymeYelp" class="itemRow">
<div class="mui-col-md-4 mui-col-xs-4">
<img src="gfx/stars_<%= yelp.rating * 10 %>.png">
</div>
<div class="mui-col-md-4 mui-col-xs-4 mui--text-center">
<%= yelp.reviewCount %> review
</div>
<div class="mui-col-md-4 mui-col-xs-4 mui--text-right">
<a href="<%=yelp.viewIntent %>"><img src="gfx/yssdk_yelp_logo.png"></a>
</div>
</div>
  `),
  'tipsTemplate': _.template(`
  <div id='bymeTips'>
  <div class="mui-row">
          <div class="mui-col-xs-10 mui--text-body2">Tips</div>
          <div class="mui-col-xs-1" style="font-family:fujicons;"><a href="<%=url %>"></a></div>
         </div>
  <div  class='scrolling-wrapper-flexbox' style="height: 200px;">
      <%_.forEach(tips, function(i) {%>
      <div class="scrollCard mui--text-body1">
              <%=i %>
          </div>
      <%}) %>
    </div>
  </div>  
  `),
  'imagesTemplate': _.template(`
    <div id='bymeImages'>
    <div  class='scrolling-wrapper-flexbox' style="height: 100%;">
        <%_.forEach(images, function(i) {%>
        <div class="imageCard">
        <img src="<%=i %>" class="scrollImg">
        </div>
        <%}) %>
      </div>
    </div>  
    `),
  'tweetsTemplate': _.template(`
    <div id='bymeTweets'>
    <div class="mui-row">
        <div class="mui-col-xs-10 mui--text-body2">Tweets</div>
        <div class="mui-col-xs-1" style="font-family:fujicons;"></div>
       </div>
    <div  class='scrolling-wrapper-flexbox' style="height: 200px;">
        <%_.forEach(tweets, function(i) {%>
        <div class="scrollCard mui--text-body1">
                <%=i %>
            </div>
        <%}) %>
      </div>
    </div>  
    `),
  'venueTitle': _.template(`
  <div class="mui--text-display1 mui--text-center" style="font-weight: 900;"><%=name %></div>
  `),
  'venueDescription': _.template(`
  <div class="mui--text-body1" ><%=description %></div>
  `),
  'venueDirections': _.template(`
  <div class="mui--text-subhead mui--text-center separate" style="" id="getDirections"><p><i class="fa fa-sm fa-map-marker mui--align-middle " ></i><%=address%></p><p>Directions</p></div>
  `),
  'openInFS': _.template(`
    <div style="margin-bottom:50px"><a href="<%=url%>">Open in Foursquare</a></div>
    `),
  'map': _.template('<div id="map"></div>'),
  'templateCurrently': _.template(`
    <div class="forecastCurrently mui-panel glassy">
          <div class="mui--text-title"><i class="medium wi wi-forecast-io-<%= icon %>"></i> <%=summary%></div>
          <div>
          <i class="small fa fa-up mui--align-middle " ></i><%=tempMax%>°
          <i class="small fa fa-down mui--align-middle " ></i><%=tempMin%>°
          </div>
          <div class="large temp<%=~~(temperature) %>"><%=temperature%>°</div>
    </div>
          `),
  'templateForecast': _.template(`
    <div class="forecastToday mui-panel glassy">
    <h3>Forecast</h3>
    <div  class='scrolling-wrapper-flexbox hourly' style="height: 100px;">
            <%_.forEach(today, function(i) {%>
            <div class="hourlyCard">
                   <div class="mui--text-center"><%=i.time %></div>
                   <div class="mui--text-center"><i class="small wi wi-forecast-io-<%= i.icon %>"></i></div>
                   <div class="mui--text-center temp<%=~~(i.temp)%> normalWeight"><%=~~(i.temp)%>°</div>
                </div>
            <%}) %>
          </div>
    
    <div>
    <div class="dailyCard">
    <%_.forEach(daily, function(i) {%>
              <div class="mui-row">
                     <div class="mui-col-xs-6 mui--text-left mui--text-title"><%=i.time %></div>
                     <div class="mui-col-xs-2 mui--text-center"><i class="mui--text-title wi wi-forecast-io-<%= i.icon %>"></i></div>
                     <div class="mui-col-xs-2 mui--text-center temp<%=~~(i.tempHigh) %> mui--text-title "><%=~~(i.tempHigh)%>°</div>
                     <div class="mui-col-xs-2 mui--text-center temp<%=~~(i.tempLow) %> mui--text-title"><%=~~(i.tempLow)%>°</div>
                  </div>
              <%}) %>
    </div>
    
  </div>
    </div>
    `),
  'templateDetails': _.template(`
    <div class="forecastDetails mui-panel glassy">
    <h3>Details</h3>
    <div class="mui-row">
          <div class="mui-col-xs-6 mui--text-left mui--text-body2">Humidity</div>
          <div class="mui-col-xs-6 mui--text-right mui--text-title"><%=~~(humidity * 100) %>%</div>
    </div>
    <div class="mui-row">
          <div class="mui-col-xs-6 mui--text-left mui--text-body2">Visibility</div>
          <div class="mui-col-xs-6 mui--text-right mui--text-title"><%=visibility %><span class="mui--text-body1">km</span></div>
    </div>
    <div class="mui-row">
          <div class="mui-col-xs-6 mui--text-left mui--text-body2">UV Index</div>
          <div class="mui-col-xs-6 mui--text-right mui--text-title"><%=uvIndex %></div>
    </div>
  <div class="mui-row">
          <div class="mui-col-xs-12 mui--text-left mui--text-body1"><%=summary%></div>
    </div>
    <h3>Wind & Pressure</h3>
  <div class="mui-row">
          <div class="mui-col-xs-6 mui-col-md-3 mui--text-left mui--text-body2">Wind</div>
          <div class="mui-col-xs-6 mui-col-md-3 mui--text-right mui--text-title"><%=windSpeed %><span class="mui--text-body1">km/h</span></div>
          <div class="mui-col-xs-6 mui-col-md-3 mui--text-left mui--text-body2">Barometer</div>
          <div class="mui-col-xs-6 mui-col-md-3 mui--text-right mui--text-title"><%=pressure %><span class="mui--text-body1">mBar</span></div>
          
    </div>
  <h3>Sun & Moon</h3>
  
    <div class="mui-row">
              <div class="mui-col-xs-3 mui--text-left mui--text-body2">Sunrise</div>
              <div class="mui-col-xs-3 mui--text-right mui--text-title"><%=sunriseTime %></div>
              <div class="mui-col-xs-3 mui--text-left mui--text-body2">Sunset</div>
              <div class="mui-col-xs-3 mui--text-right mui--text-title"><%=sunsetTime %></div>
              
        </div>
        <div class="mui-row">
            <div class="mui-col-xs-12 mui--text-right mui--text-title"><%=moonphase %></div>
                </div>
    </div>
      `),
  'templateDirections': _.template(`
  <%_.forEach(directions, function(i) {%>
            <div class="itemRow mui--align-middle">
                   <span class="mui--text-dark mui--text-subhead"><%=i %></span>
                </div>
  <%}) %>
  `)
};

module.exports = templates;

/*
<div data-id="4b926c3af964a52068f833e3" class="itemRow mui--align-middle">
<img class="mui--align-middle" src="https://ss3.4sqi.net/img/categories_v2/nightlife/pub_32.png" width="32px" height="32px" style="-webkit-filter: invert(100%);">

<span class="mui--text-dark mui--text-subhead">The Mayflower</span> <span class="mui--text-caption mui--text-dark-secondary">Pub</span>      </div>
 */
