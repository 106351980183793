const Backbone = require('backbone');
const _ = require('underscore');

const fsItem = Backbone.Model.extend({

});

const FSCollection = Backbone.Collection.extend({
  'model': fsItem
});

// const fsCollection = new FSCollection();

const FSItemView = Backbone.View.extend({
  'tagName': 'div',
  'className': 'itemRow mui--align-middle',
  'template': _.template(`
<img class='mui--align-middle' src="<%= icon %>" width="32px" height="32px" style="-webkit-filter: invert(100%);"/><span class="mui--text-dark mui--text-subhead"><%= name %></span> <span class="mui--text-caption mui--text-dark-secondary">(<%= distance %>km)</span> <span class="mui--text-caption mui--text-dark-secondary"><%= category %></span>      `),
  'initialize': function() {
    this.render();
  },
  'attributes': function() {
    return {
      'data-id': this.model.id
    };
  },

  'render': function() {
    // console.log(this.model.attributes);
    this.$el.html(this.template(this.model.toJSON()));
  }
});

module.exports = { fsItem, FSCollection, FSItemView };
