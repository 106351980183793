const _ = require('underscore');
const Backbone = require('backbone');
const geolocation = require('geolocation');
const TimeFormat = require('hh-mm-ss');
const request = require('request');

const { distance } = require('./libs/utils');

// const locationData = require('./portGlasgow-Aldi-2018-03-09.json');

const LocationModel = Backbone.Model.extend({
  'defaults': {

    // defaults go here - "children" may be contained here
  },
  'tick': function () {
    const p = locationData[this.pos];
    const now = new Date();

    console.log(p);
    const location = { 'latitude': p.lat, 'longitude': p.lng, 'timestamp': now.getTime() };
    this.processPosition(location);

    this.timerID = setTimeout(
      () => {
        this.pos++;
        this.tick();
      },
      p.time
    );
  },
  'initialize': function () {
    // this.listenTo(this, 'change sync reset', this.onChange);

    // https://opencagedata.com/dashboard

    //
    // API key
    // 893ab539eca84b5ca7a54cb03ef23443

    const geoOptions = {
      'maximumAge': 5 * 60 * 1000,
      'timeout': 10 * 1000
    };

    this.pos = 0;
    this.moveTimer = 0;
    this.throttler = 0;

    geolocation.options = geoOptions;

    geolocation.on('error', function (err) {
      console.warn('Geolocation error');
      console.error(err);
    });

    const updateLocation = (position) => {
      const latitude = Number.parseFloat(position.coords.latitude).toFixed(6);
      const longitude = Number.parseFloat(position.coords.longitude).toFixed(6);

      const location = { 'latitude': latitude, 'longitude': longitude, 'timestamp': position.timestamp };
      console.log('*** Updating Loc', location);

      this.processPosition(location);
    };

    const updateMotion = (position) => {
      const latitude = Number.parseFloat(position.coords.latitude).toFixed(6);
      const longitude = Number.parseFloat(position.coords.longitude).toFixed(6);
      
      if (!this.has('motion')) {
        const now = new Date();
        const ts = now.getTime();

        const motion = {
          'cur': {
            'lat': latitude,
            'lon': longitude,
            'speed': 0,
            'ts' : ts
          },
          'prev': {
            'lat': latitude,
            'lon': longitude,
            'speed': 0,
            'ts' : ts
          },
          'speed': 0,
          'distance' : 0

        };

        this.set('motion', motion);
      }
      else {
        //
        const motion = this.get('motion');
        const now = new Date();
        const ts = now.getTime();

        motion.prev = Object.assign({}, motion.cur);

        motion.cur.lat = latitude;
        motion.cur.lon = longitude;
        motion.cur.ts = ts;

        const timeS = (ts - motion.prev.ts) / 1000.0;

        const dist = distance(motion.prev.lat, motion.prev.lon, latitude, longitude);

        const speedMps = dist / timeS;
        const speedKph = (speedMps * 3600.0);

        motion.cur.speed = Number.parseFloat(speedKph).toFixed(3);
        motion.speed = Number.parseFloat(speedKph).toFixed(3);
        motion.distance = Number.parseFloat(dist).toFixed(3);
        console.log(motion);
        this.set('motion', motion);
        this.set('moving', (speedKph > 5.0));
      }
    };

    const updatelocationthrottled = _.throttle(updateLocation, 120000);
    const updateMotionhrottled = _.throttle(updateMotion, 15000);

    geolocation.on('change', _.throttle(function (position) {
      console.log('Location update');
      updatelocationthrottled(position);
      updateMotionhrottled(position);
    }.bind(this), 15000));

    this.watcher = geolocation.createWatcher();

    this.listenTo(this, 'change:location', this.onChange);
    // this.tick();
  },
  'onChange': function () {
    console.log('>> Location updated');
    console.log(JSON.stringify(this.get('location')));
  },
  'processPosition': function (pos) {
    console.log('processPosition');
    const { latitude, longitude, timestamp } = pos;

    // const current = this.get('location');
    const current = this.toJSON();
    // console.log('># current', current);

    const myCoords = {
      'home': {
        'lat': 51.490002, 'long': -0.140245
      },
      'work': {
        'lat': 51.508471, 'long': -0.068798
      }
    };

    // rawburn house 51.490002, -0.140245
    // thomas more 51.5084707,-0.068798

    // 55.872407, -3.549003
    const homeDistance = distance(myCoords.home.lat, myCoords.home.long, latitude, longitude);
    const workDistance = distance(myCoords.work.lat, myCoords.work.long, latitude, longitude);
    const atHome = (homeDistance < 0.10);
    const atWork = (workDistance < 0.10);
    const atHomeOrWork = (atHome || atWork);
    const latlong = { 'lat': latitude, 'lon': longitude };
    const latlong4 = { 'lat': Number.parseFloat(latitude).toFixed(4), 'lon': Number.parseFloat(longitude).toFixed(4) };
    const ll = `${latitude},${longitude}`;
    const llFixed = `${Number.parseFloat(latitude).toFixed(4)},${Number.parseFloat(longitude).toFixed(4)}`;
    const llSix = `${Number.parseFloat(latitude).toFixed(6)},${Number.parseFloat(longitude).toFixed(6)}`;
    const llShort = `${Number.parseFloat(latitude).toFixed(1)},${Number.parseFloat(longitude).toFixed(1)}`;
    const moving = true;

    const newLocation = {
      homeDistance,
      workDistance,
      latitude,
      longitude,
      atHome,
      atWork,
      atHomeOrWork,
      timestamp,
      ll,
      llFixed,
      llSix,
      llShort,
      latlong4,
      moving,
      latlong,
      'city': '',
      'cityCC': ''
    };

    console.log('homeDistance', homeDistance, 'workDistance:', workDistance);
    // console.log('>> NewLocation', JSON.stringify(newLocation));
    //  const distanceFromLast = distance(current.latitude, current.longitude, latitude, longitude);

    if (!_.has(current, 'atHomeOrWork')) {
      console.info('>> Location:geocoder request');

      request({
        'url': `${window.loc}/geocode/${Number.parseFloat(latitude).toFixed(4)}/${Number.parseFloat(longitude).toFixed(4)}`,
        'method': 'GET',
        'json': true
      }, function(err, res, body) {
        if (err)
          console.error(err);
        else {
          console.log('New geocode', res);
          const body = res.body;
          const __city = body.neighborhood || body.village || body.suburb || body.city || body.county;
          newLocation.city = __city;
          newLocation.cityCC = `${__city},${body.countryCode}`;
          newLocation.address = body.formatted;
          newLocation.lastGeocode = { 'lat': latitude, 'lng': longitude, 'timestamp': timestamp };
          this.set(newLocation);
        }
      }.bind(this));
    }
    else {
      newLocation.city = current.city;
      const currentTime = new Date().getTime();
      const distanceFromLast = distance(current.latitude, current.longitude, latitude, longitude);
      const lastGeocode = this.get('lastGeocode');
      const distanceFromLastGeocode = distance(lastGeocode.lat, lastGeocode.lng, latitude, longitude);

      const currentSince = currentTime - current.timestamp;
      const sinceLastGeocode = currentTime - lastGeocode.timestamp;

      console.log('>> distance from last record', distanceFromLast, TimeFormat.fromMs(currentSince, 'hh:mm:ss'));
      // console.log('>> distanceFromLastGeocode', distanceFromLastGeocode, TimeFormat.fromMs(timestamp - lastGeocode.timestamp, 'hh:mm:ss'));
      console.log('>> distanceFromLastGeocode', distanceFromLastGeocode, TimeFormat.fromMs(sinceLastGeocode, 'hh:mm:ss'));
      // console.log(`(currentTime:${currentTime}, timestamp:${timestamp}, lastGeocode.timestamp:${lastGeocode.timestamp})`);
      // console.log('(currentTime - current.timestamp > 900000) ', (currentTime - current.timestamp > 900000));
      // if ((distanceFromLast > 0.5 && distanceFromLast < 2.0) || (timestamp - current.timestamp > 900000)) {
      if (((distanceFromLast > 0.80 && distanceFromLast < 2.0) && (currentSince > 120000)) || ((currentSince > 900000) && (sinceLastGeocode < 1.8e+6))) {
        // dont bother re geocoding
        console.log('Slightly moved from previous');
        // this.set('location', newLocation);
        // this.set('moving', moving);
        this.set(newLocation);
      }
      else if (((distanceFromLastGeocode >= 2.0) && (sinceLastGeocode > 120000)) || (sinceLastGeocode >= 1.8e+6)) {
        console.log('>> Moved from previous', sinceLastGeocode, (sinceLastGeocode >= 1.8e+6));
        console.info('>> Location:geocoder request');
        request({
          'url': `${window.loc}/geocode/${llFixed.replace(',','/')}`,
          'method': 'GET',
          'json': true
        }, function(err, res, body) {
          if (err)
            console.error(err);
          else {
            console.log('New geocode', res);
            const body = res.body;
            const __city = body.neighborhood || body.village || body.suburb || body.city || body.county;
            newLocation.city = __city;
            newLocation.cityCC = `${__city},${body.countryCode}`;
            newLocation.address = body.formatted;
            newLocation.lastGeocode = { 'lat': latitude, 'lng': longitude, 'timestamp': timestamp };
            this.set(newLocation);
          }
        }.bind(this));
      }
    }

    clearTimeout(this.moveTimer);
    this.moveTimer = setTimeout(
      () => {
        console.log('>>>> STOPPED MOVING');
        //        const location = this.get('location');
        //        location.moving = false;
        this.set('moving', false);
        const lastGeocode = this.get('lastGeocode');
        const currentTime = new Date().getTime();

        const sinceLastGeocode = currentTime - lastGeocode.timestamp;

        console.log('>> stopped:', TimeFormat.fromMs(sinceLastGeocode, 'hh:mm:ss'));
      },
      30000
    );
  }

});

const LocationView = Backbone.View.extend({
  'initialize': function (options) {
    this.model.bind('change', this.render, this);
  }, 'template': _.template(`
      <div class="">Moving:<%=moving%></div>
            `),
  'render': function () {
  //  this.$el.html(this.template(this.model.attributes));

    console.log('>> location attributes', this.model.attributes);

    return this;
  }
});

module.exports = { LocationModel, LocationView };

// https://www.npmjs.com/package/node-geocoder
