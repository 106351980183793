const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get } = require('lodash');
const { reduceEuronews } = require('./libs/reducers');
const TimeFormat = require('hh-mm-ss');
const { hourFloor } = require('./libs/utils');

const NewsItem = Backbone.Model.extend({

});

const NewsCollection = Backbone.Collection.extend({
  'model': NewsItem
});

const newsCollection = new NewsCollection();

const newsItemView = Backbone.View.extend({
  'tagName': 'div',
  'className' : 'scrollCard',
  'template': _.template(`
    
    <div class="mui--text-subhead mui--text-accent"><%=title%></div>
    <div class="published mui--text-dark-secondary mui--text-caption"><%=pubdate%></div>
   
    <p class="mui--text-body1"><%=description%></p>

          `),
  'initialize': function() {
    this.render();
  },
  'attributes': function() {
    return {
      'data-guid': this.model.get('guid')
    };
  },

  'render': function() {
    this.$el.html(this.template(this.model.toJSON()));
  }

});

const NewsModel = Backbone.Model.extend({
  'defaults' : function (obj) {
    // return a new object
    return {
      'update' : new Date().getTime()
    };
  }, 'initialize': function() {
    this.newsCollection = newsCollection;
    this.listenTo(this, 'change:update', this.onChange);
    this.getNews = _.throttle(this.getNewsReal, 6000);

    this.getNews();
  },
  'onChange': function() {
    this.getNews();
  },
  'getNewsReal': function() {
    // const ll = this.get('llShort');
    console.info('>> News:getNewsReal');
    request({
      'url': `${window.loc}/news`,
      'method': 'GET', 'qs': {
        'limit': 10,
        'w' : hourFloor()
      }
    }, function(err, res, body) {
      if (err)
        console.error(err);
      else {
        console.log('statusCode', res.statusCode);
        const fsJSON = JSON.parse(body);
        // console.log(body);
        const newItems = [];
        const items = fsJSON.items.slice(0, 10);
        for (const item of items)
          newItems.push(reduceEuronews(item));

        this.newsCollection.reset(newItems);

        this.logUpdate();
      }
    }.bind(this));
  }, 'logUpdate': function() {
    console.log('News logging:');

    const time = new Date().getTime() ;

    this.set('time', time, {silent: true});

    this.timerID = setTimeout(
      () => this.tick(),
      3.6e+6 + 1000
    );

    // console.log(this);
  },
  'tick': function() {
    console.log('Set update');
    this.set('update', new Date().getTime());
  }

});

const NewsView = Backbone.View.extend({
  'className': '',
  'template': _.template(`
    <div class="mui--text-title cardTitle">Latest news</div>
                <div id="news" class="scrolling-wrapper-flexbox"></div>
                <div id='newsMore' class="cardLink">
                    <i class="seemore fa fa-forward mui--align-middle " ></i> <span class="seemore mui--align-middle">More news</span>
                </div>
    `),

  'initialize': function(options) {
    this.eventBus = options.eventBus;
    this.newsCollection = newsCollection;

    this.newsCollection.on('all', function(eventName) {
      console.log(`${eventName } was triggered!`);
    });

    this.newsCollection.bind('reset', this.render, this);

    this.eventBus.on('focused', this.focused, this);
  },
  'attributes': function() {
    return {
      'class': 'horizontal-scroll-wrapper squares'
    };
  },

  'render': function() {
    console.info('>> News:Render');

    this.$el.empty();

    this.$el.append(this.template());

    this.$news = this.$el.find('#news');

    this.newsCollection.each(function(item) {
      const niView = new newsItemView({ 'model': item });

      this.$news.append(niView.el);
    }, this);

    this.$el.show();
  }, 'events': {
    'click #newsMore': 'doMoreNews',
    'click .scrollCard': 'doClick'

  }, 'doClick': function(d) {
    // console.log('Do click', d);
    const id = get(d, 'currentTarget.dataset.guid', '');
    console.log(id);
    this.eventBus.trigger('showNews', id);
  }, 'focused': function() {
    console.log('>> News received focus msg');
    const now = new Date().getTime();
    const since = now - this.model.get('time');

    console.log(`News was last updated: ${TimeFormat.fromMs(since, 'hh:mm')} ago`);

    if (since > (60 * 1000 * 60) )
      this.model.set('update', now);
  },
  'doMoreNews': function() {
    // console.log('doMoreNews', this);

    this.eventBus.trigger('showNewsList');
  }
});

module.exports = { NewsModel, NewsView };
