const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get, isEmpty } = require('lodash');
const { createPanel, addPanel } = require('./libs/panel');
const templates = require('./libs/templates');
const TimeFormat = require('hh-mm-ss');
const { hourFloor } = require('./libs/utils');
const {reduceForcastToday, reduceForcastDaily} = require("./libs/reducers");
const fecha = require('fecha');

const ForecastModel = Backbone.Model.extend({
  'defaults': function (obj) {
    // return a new object
    return {
      'update': new Date().getTime(),
      'last': new Date().getTime()
    };
  },
  'initialize': function() {
    this.listenTo(this, 'change:ll', this.onChange);
    this.listenTo(this, 'change:update', this.onChange);
  },
  'onChange': function() {
    console.log('>> LL changed:', this.get('ll'));
    this.getForecast();
  },
  'moonCalc': function(moonPhase) {
    let output = '';
    if (moonPhase === 0.0)
      output = '🌕 New moon';
    else if (moonPhase >= 0.1 && moonPhase < 0.25)
      output = '🌔 waxing crescent';
    else if (moonPhase === 0.25)
      output = '🌓 First Quarter';
    else if (moonPhase > 0.25 && moonPhase < 0.5)
      output = '🌒 waxing gibbous';
    else if (moonPhase === 0.5)
      output = '🌑 Full moon';
    else if (moonPhase > 0.5 && moonPhase < 0.75)
      output = '🌘 Waning gibbous';
    else if (moonPhase === 0.5)
      output = '🌗 Third quarter';
    else if (moonPhase > 0.75)
      output = '🌖 Waning crescent';

    return output;
    // ['🌑', '🌘', '🌗', '🌖', '🌕', '🌔', '🌓', '🌒']
    // a value of 0 corresponds to a new moon, 0.25 to a first quarter moon, 0.5 to a full moon, and 0.75 to a last quarter moon. (The ranges
    // in between these represent waxing crescent, waxing gibbous, waning gibbous, and waning crescent moons, respectively.)
  },
  'getForecast': function() {
    const ll = this.get('ll');
    request({
      'url': `${window.loc}/forecast/${ll.replace(',','/')}`,
      'method': 'GET',
    }, function(err, res, body) {
      console.log('statusCode', res.statusCode);
      if (err)
        console.error(err);
      else{
        // console.log(body);
        const fsJSON = JSON.parse(body);
        // console.log(fsJSON);
        fsJSON.last = new Date().getTime();

        let sunrise = fsJSON.details.sunriseTime
        let sunset = fsJSON.details.sunsetTime
        let moonphase = fsJSON.details.moonphase

        fsJSON.details.sunriseTime = fecha.format( new Date(sunrise * 1000), 'HH:mm');
        fsJSON.details.sunsetTime = fecha.format( new Date(sunset * 1000), 'HH:mm');
        fsJSON.details.moonphase = this.moonCalc(moonphase);
        console.log("fsJSON:details:", fsJSON.details)
        this.set(fsJSON);
        // console.log(body);

        this.set('time', Date.now());

        this.logUpdate();
      }
    }.bind(this));
  }, 'logUpdate': function() {
    console.log('Forecast logging:');

    const log = { 'lat' : this.get('latitude'), 'long': this.get('longitude'), 'time': new Date().getTime() };

    this.set('log', log, {silent: true});

    this.timerID = setTimeout(
      () => this.tick(),
      3.6e+6 + 1000
    );

    // console.log(this);
  },
  'tick': function() {
    console.log('Set update');
    this.set('update', new Date().getTime());
  }

});

const ForecastView = Backbone.View.extend({
  'initialize': function(options) {
    this.eventBus = options.eventBus;

     this.model.on('all', function(eventName) {
      console.log(`FORECAST ${eventName } was triggered!`);
    });

    this.model.bind('change:time', this.doRender, this);
    this.eventBus.on('showForecast', this.showForecastPanel, this);
    this.eventBus.on('focused', this.focused, this);
    this.eventBus.on('renderForecase', this.doRender, this);
  },

  'showForecastPanel': function(ll) {
    console.log('Showing forecast', ll);
    const prevll = this.model.get('ll');
    const lastTime = this.model.get('last');
    const now = new Date().getTime();

    // (60 * 1000 * 60)
    const hour = (new Date()).getHours();
    this.model.set('ll', ll);

    this.$newPanel = createPanel({ 'title':'Weather forecast', 'divId':'weatherP' });

    this.$el = addPanel(this.$newPanel);
    this.$el.addClass('animTrans');

    if (hour >= 6 && hour < 18 )
      this.$el.addClass('weatherDay');
    else
      this.$el.addClass('weatherEvening');

    this.$el.empty();
    this.$newPanel.show();

    console.log(this.model);
    if (prevll === ll)
      if (now > lastTime + (60 * 1000 * 60)) {
        this.model.set('update', now);
      }
      else {
        this.doRender();
      }
  },
  'events': {
    'click .closebutton': 'doClick'
  },
  'doClick': function(d) {
    console.log('Do click', d);
    const id = get(d, 'currentTarget', '');
    console.log(id);
    // this.eventBus.trigger('showNews', id);
  },
  'doClose': function(d) {
    console.log('close??');
  },
  'doRender': function() {
    // this.$el.html(this.template(this.model.get('article')));
    console.log('ForecastView::doRender');
    const html = [];

    const currently = this.model.get('currently');
    const forcastToday = { 'today':reduceForcastToday(this.model.get('forcastToday')), 'daily':reduceForcastDaily(this.model.get('dailyForecast')) };
    const details = this.model.get('details');
    html.push('<div class="">');

    console.log('currently');
    console.log(JSON.stringify(currently));
    try {
      html.push(templates.templateCurrently(currently));

    }
    catch(e) {
      console.log('ERROR!! Forecast templating failed');
      console.error(e);
    }

    try {

      html.push(templates.templateForecast(forcastToday));


    }
    catch(e) {
      console.log('ERROR!! Forecast templating failed');
      console.error(e);
    }

    try {
      html.push(templates.templateDetails(details));

    }
    catch(e) {
      console.log('ERROR!! Forecast templating failed');
      console.error(e);
    }


    html.push('</div>');
    html.push('<div class="endbumper"></div>');

    this.$el.addClass(this.backgroundGen(currently.icon));

    this.$el.html(html.join(''));
  },
  'backgroundGen': function(icon) {
    const hour = (new Date()).getHours();
    const suffix = (hour >= 6 && hour < 18 ) ? '_d' : '_n';
    let output = '';
    switch (icon) {

      case 'clear':
        output = `clear${suffix}`;
        break;
      case 'clear-day':
        output = 'clear_d';
        break;
      case 'clear-night':
        output = 'clear_n';
        break;
      case 'rain':
        output = `rain${suffix}`;
        break;
      case 'snow':
      case 'sleet':
      case 'hail':
        output = `snow${suffix}`;
        break;
      case 'fog':
        output = `foggy${suffix}`;
        break;
      case 'wind':
      case 'cloudy':
        output = `cloudy${suffix}`;
        break;
      case 'partly-cloudy-day':
        output = 'cloudy_d';
        break;
      case 'partly-cloudy-night':
        output = 'cloudy_n';
        break;

      case 'thunderstorm':
      case 'tornado':
        output = `storm${suffix}`;
        break;

      default:
        console.log(`Sorry, we are out of ${ icon }.`);

    }

    return output;
  }, 'focused': function() {
    console.log('>> Forecast received focus msg');
    if (!this.model.has('log')) {
      console.log('No log yet');

      return ;
    }
    const now = new Date().getTime();
    const log = this.model.get('log');
    const since = now - log.time;

    console.log(`Forecast was last updated: ${TimeFormat.fromMs(since, 'hh:mm')} ago`);

    if (since > (60 * 1000 * 60) )
      this.model.set('update', now);
  }

});

module.exports = { ForecastModel, ForecastView };

