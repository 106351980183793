const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get } = require('lodash');
const { reduceNearby } = require('./libs/reducers');
const { toHour, hourFloor } = require('./libs/utils');
const TimeFormat = require('hh-mm-ss');

const { FSDetailView } = require('./Foursquare');
const { fsItem, FSCollection, FSItemView } = require('./libs/fsbits');

const fsCollection = new FSCollection();

const NearbyModel = Backbone.Model.extend({
  'defaults' : function (obj) {
    // return a new object
    return {
      'update' : new Date().getTime()
    };
  }, 'initialize': function() {
    this.fsCollection = fsCollection;
    this.timerID = 0;
    this.tick();
    this.set('totalResults', 0);
    this.listenTo(this, 'change:llFixed change:atHome change:section change:update', this.onChange);
    this.throttledGetNearby = _.throttle(this.getNearby, 6000);
  },
  'tick': function() {
    const hour = parseInt((new Date()).getHours().toString(), 10);
    const section = (hour >= 11 && hour <= 14) ? '13000' : '0'; // 13000 = category for Dining and drinking, 0 should force it to search for anything

    this.set('section', section);

    this.timerID = setTimeout(
      () => this.tick(),
      toHour()
    );
  },
  'onChange': function() {
    console.log('>> Nearby:Changed: ', this);
    if (this.get('atHome')) {
      console.log('At home, so no fetching...');
      this.logUpdate();
    }
    else
      this.throttledGetNearby();
  },
  'getNearby': function() {
    const llFixed = this.get('llFixed').replace(',','/');

    const hour = parseInt((new Date()).getHours().toString(), 10);
    const section = this.get('section');
    const time = new Date().getTime() ;

    const soqval = section;

    const soqtype = "s";

    const lastUpdate = time - (this.get('time') || 0);

    console.log('>> Nearby section:', hour, section);
    console.info('>> Nearby:request V2');
    console.log(`>> Nearby last fetch: ${TimeFormat.fromMs(lastUpdate, 'hh:mm')} ago`);

    if (lastUpdate > 120000) {
      const url = new URL(`${window.loc}/fsexplore/${llFixed.replace(',','/')}/${soqval}/${soqtype}/10`);



      fetch(url)
        .then(res => {
          console.log('statusCode', res.statusCode);

          console.warn(res.status);

          return res.text();
        })
        .then(function(body) {
          const fsJSON = JSON.parse(body);

/*
          const groups = get(fsJSON, 'results');
          const items = groups[0].items;
*/
          const items = get(fsJSON, 'results');
          const newItems = [];
          this.set('totalResults', items.length);
          for(const item of items)
            newItems.push(reduceNearby(item));

          this.fsCollection.reset(newItems);
          this.logUpdate();
        }.bind(this))
        .catch(function(error) {
          console.error(error);
        });
    }
  }, 'logUpdate': function() {
    console.log('Nearby logging:');

    const time = new Date().getTime() ;

    this.set('time', time, { 'silent': true });
  }
});

const NearbyView = Backbone.View.extend({
  'id':'nearby',
  'className': '',
  'initialize': function(options) {
    this.eventBus = options.eventBus;
    this.location = options.location;
    this.fsCollection = fsCollection;

    _.bindAll(this, 'doMoreClick');

    // this.model.bind('change', this.render, this);
    this.location.bind('change:llFixed', this.updateLocation, this);
    this.location.bind('change:atHome', this.atHome, this);

    this.$nearby = $('#nearby');

    this.fsCollection.bind('reset', this.render, this);
    this.eventBus.on('focused', this.focused, this);

    this.other = $('<div id="nearbyOther" class="cardLink"><i class="seemore fa fa-forward mui--align-middle " ></i> <span class="seemore mui--align-middle">Other locations</span></div>');
  },
  'events': {
    'click .itemRow': 'doClick',
    'click #nearbyOther': 'doMoreClick'
  },
  'updateLocation': function(l) {
    console.log('>> Nearby Location has changed...');

    if (l.has('atHome')) {
      const llFixed = l.get('llFixed');
      const atHome = l.get('atHome');
      this.model.set({ 'llFixed': llFixed, 'atHome': atHome });
    }
    else
      console.log('>> Nearby No location yet');
  },
  'blah': function() {
    console.log('nearby render');
  },
  'render': function() {
    console.info('>> Nearby:Render');
    const totalResults = this.model.get('totalResults');
    console.log('>> totalResults', totalResults);
    this.$el.empty();
    let limit = 0;
    this.fsCollection.each(function(item) {
      if (limit <= 2) {
        const fsView = new FSItemView({ 'model': item });
        this.$el.append(fsView.el);

      }

      limit++;
      // this.$el.append(personView.el); // adding all the person objects.
    }, this);

    this.$el.append(this.other);
    if (totalResults > 0)
      this.$el.parent().show();
    else
      this.$el.parent().hide();
  },
  'doClick': function(d) {
    console.log('Do click', d);
    const id = get(d, 'currentTarget.dataset.id', '');
    console.log(id);
    this.eventBus.trigger('showVenueDetail', id);
    const fsdetail = new FSDetailView({ 'fsID':id });
  }, 'focused': function() {
    console.log('>> Nearby received focus msg');
    if (!this.model.has('time')) {
      console.log('No time yet');

      return ;
    }
    if (this.location.get('atHome')) {
      console.log('Still at home');

      return;
    }
    const now = new Date().getTime();
    const since = now - this.model.get('time');
    console.log(this.model.get('time'));

    console.log(`Nearby was last updated: ${TimeFormat.fromMs(since, 'hh:mm')} ago`);

    if (since > (60 * 1000 * 60) )
      this.model.set('update', now);
  }, 'atHome': function() {
    if (this.location.get('atHome'))
      this.$el.parent().hide();
  },
  'doMoreClick': function(d) {
    console.log('Do more click', d);

    const llFixed = this.model.get('llFixed');
    const section = this.model.get('section');
    const data = { llFixed, section, 'limit':20 };

    //  console.log('Data', data);

    this.eventBus.trigger('showNearbyList', data);
  }

});

module.exports = { NearbyModel, NearbyView };
// 55.949443, -4.570721;
