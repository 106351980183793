function partOfDay(timeString, today) {
  console.log(new Date());
  if (timeString === undefined || timeString === null)
    timeString = (new Date()).getHours().toString();

  if (today === undefined)
    today = false;

  const hours = timeString.substring(0, 2);
  let dayBit = '';

  console.log('Hours', hours);

  if (hours >= 0 && hours < 4)
    dayBit = 'Late Night';

  else if (hours >= 4 && hours < 7)
    dayBit = 'Early Morning';

  else if (hours >= 7 && hours < 12)
    dayBit = 'Morning';

  else if (hours >= 12 && hours < 17)
    dayBit = 'Afternoon';

  else if (hours < 21)
    dayBit = 'Evening';

  else
    dayBit = 'Night';

  if (today)
    if (dayBit === 'night') {
      dayBit = 'tonight';
    }
    else {
      dayBit = `this ${ dayBit}`;
    }

  console.log('partOfDay', dayBit);

  return dayBit;
}

function toHour(extra = 0) {
  const now = new Date();

  return (3600000 - (now.getTime() % 3600000)) + extra;
}

function hourFloor() {
  const now = new Date();

  return parseInt(~~(now.getTime() / 3600000) * 3600000, 10).toString(32);
}

function distance(lat1, lon1, lat2, lon2) {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2 +
          c(lat1 * p) * c(lat2 * p) *
          (1 - c((lon2 - lon1) * p)) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

function splitURL(url) {

}

const maybePluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

module.exports = { partOfDay, toHour, hourFloor, distance, maybePluralize };

