const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get } = require('lodash');
// const { reduceNearby } = require('./libs/reducers');
const { toHour } = require('./libs/utils');
const TimeFormat = require('hh-mm-ss');
const fecha = require('fecha');

const TrafficModel = Backbone.Model.extend({
  'defaults' : function (obj) {
    // return a new object
    return {
      'update' : new Date().getTime(),
      'mode' : 0
    };
  }, 'initialize': function() {
    this.timerID = 0;
    this.updateID = 0;
    this.tick();
    this.listenTo(this, 'change:update change:llFixed', this.getTraffic);
  },
  'tick': function() {
    const hour = (new Date()).getHours();

    const day = new Date().getDay();
    console.log('>> day', day);

    let doRequest = false;
    if (day !== 0 && day !== 6)
      doRequest = (((hour >= 7) && (hour <= 9)) || ((hour >= 17) && (hour <= 19)));

    // doRequest = true;

    const delay = doRequest ? 600000 : toHour();

    if (this.has('latlong') && doRequest) {
      this.updateID = 1;
      this.getTraffic();
    }
    else
      this.set('mode', 0);

    console.log('doRequest', doRequest);
    console.log('Delay', delay);
    this.timerID = setTimeout(
      () => this.tick(),
      delay
    );
  },
  'onChange': function() {
    this.getTraffic();
  },
  'getTraffic': function() {
    console.log('Get Traffic');
    // olat, olon, dlat, dlon
    const override = false;
    const time = new Date().getTime() ;
    const hour = (new Date()).getHours();
    const latlong = this.get('latlong');
    let qs;

    let mode = 0;
    if (!this.has('latlong'))
      return;

    const myCoords = { 'home': {
      'lat':51.490002, 'long':-0.140245
    },
    'work':{
      'lat':51.5084707, 'long':-0.068798
    } };

    //  55.872407, -3.549003

    // const latlong = { 'lat':latitude, 'lon':longitude };
    if ((hour >= 7) && (hour <= 9)) {
      this.set('dest', 'Work');
      mode = 1;
      qs = {
        'olat': latlong.lat, 'olon': latlong.lon, 'dlat': myCoords.work.lat, 'dlon': myCoords.work.long
      };
    }
    else if (((hour >= 17) && (hour <= 19)) || override) {
      mode = 1;
      this.set('dest', 'Home');
      qs = {
        'olat': latlong.lat, 'olon': latlong.lon, 'dlat': myCoords.home.lat, 'dlon': myCoords.home.long
      };
    }
    const lastUpdate = time - (this.get('time') || 0);
    console.log('lastupdate', lastUpdate);
    console.log(`>> Traffic last fetch: ${TimeFormat.fromMs(lastUpdate, 'hh:mm')} ago`);
    this.set('mode', mode);
    if (lastUpdate > 120000 && mode === 1)
      request({
        'url': `${window.loc}/traffic`,
        'method': 'GET',
        'qs': qs
      }, function(err, res, body) {
        if (err)
          console.error(err);
        else {
          console.log('statusCode', res.statusCode);
          const now = new Date().getTime();
          const trafficJSON = JSON.parse(body);

          console.log('>> trafficJSON', trafficJSON);

          this.set(trafficJSON);
          console.log(this.attributes);
          this.logUpdate();
        }
      }.bind(this));
  }, 'logUpdate': function() {
    console.log('>> Traffic logging:');

    const time = new Date().getTime() ;

    this.set('time', time, {silent: true});
  }

});

const TrafficView = Backbone.View.extend({
  'id':'traffic',
  'className': '',
  'initialize': function(options) {
    this.eventBus = options.eventBus;
    this.location = options.location;

    this.location.bind('change:llFixed', this.updateLocation, this);
    this.model.bind('change:timestamp', this.render, this);
    this.model.bind('change:mode', this.render, this);
    this.eventBus.on('focused', this.focused, this);
  }, 'focused': function() {
    console.log('>> Traffic received focus msg');
    console.log(this.model.get('time'));
    if (typeof this.model.get('time') === 'undefined')
      return;

    const now = new Date().getTime();
    const since = now - this.model.get('time');

    console.log(`Traffic was last updated: ${TimeFormat.fromMs(since, 'hh:mm')} ago`);

    if (since > (60 * 1000 * 10) )
      this.model.set('update', now);
  },
  'template': _.template(`
    <div class="">
    <div class="itemRow mui--align-middle"><i class=" fa <%= "fa-" + dest.toLowerCase() %> fa-2x mui--align-middle <%= className %>"></i> <%=readable%> to <%= dest.toLowerCase() %> <span class="<%= className %>"><%=traffic %></span></div>
      
    
    </div>
          `),
  'updateLocation': function(l) {
    console.log('>> Nearby Location has changed...');

    if (l.has('atHome')) {
      const llFixed = l.get('llFixed');
      const latlong = l.get('latlong');
      this.model.set({ 'llFixed': llFixed, 'latlong': latlong });
    }
    else
      console.log('>> Nearby No location yet');
  },
  'render': function() {
    console.log('>> Traffic:Render');

    this.$el.empty();

    if (this.model.get('mode') === 0)
      this.$el.parent().hide();
    else
    if (this.model.has('readable')) {
      console.log(this.template(this.model.attributes));
      this.$el.html(this.template(this.model.attributes));
      this.$el.parent().show();
    }
  }

});

const parts = new Map(
  [
    ['0', 'Depart'],
    ['9', 'left_turn'],
    ['13', 'right_turn'],
    ['11', 'continue'],
    ['23', 'right_fork'],
    ['17', 'left_exit'],
    ['19', 'left_ramp'],
    ['29', 'turn'],
    ['', '']

  ]

);

module.exports = { TrafficModel, TrafficView };
