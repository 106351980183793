const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('backbone');
const request = require('request');
const { get, has, isEmpty } = require('lodash');
const { createPanel, addPanel } = require('./libs/panel');

const templates = require('./libs/templates');

const GotoCardModel = Backbone.Model.extend({
  'initialize': function() {
    this.listenTo(this, 'change:detail', this.getDirections);
  },
  'getDirections': function() {
    const { from, to } = this.get('detail');
    console.log('goto from', from);
    console.log('goto to', to);

    const qs = {
      'olat':to.lat, 'olon': to.long, 'dlat': from.lat, 'dlon': from.long
    };

    console.log('qs', qs);

    request({
      'url': `${window.loc}/traffic`,
      'method': 'GET',
      'qs': qs
    }, function(err, res, body) {
      if (err)
        console.error(err);
      else {
        console.log('statusCode', res.statusCode);
        const now = new Date().getTime();
        const gotoJSON = JSON.parse(body);

        console.log('>> gotoJSON', gotoJSON);

        this.set('directions', gotoJSON.directions);
        console.log(this.attributes);
        // this.logUpdate();
      }
    }.bind(this));
  }

});

const GotoCardView = Backbone.View.extend({
  'initialize': function(options) {
    this.eventBus = options.eventBus;
    this.location = options.location;

    this.location.bind('change', this.updateMyLocation, this);

    /* this.model.on('all', function(eventName) {
      console.log(`${eventName } was triggered!`);
    });*/

    this.model.bind('change:directions', this.doRender, this);
    this.eventBus.on('showDirections', this.showDirectionsPanel, this);
  },

  'updateMyLocation': function(l) {
    console.log('Goto:My location Changed', l);
  },
  'template': _.template(`
    <div id='gotoDetails' class="mui-container" style="margin-bottom: 50px;">
        <div><h1>Details</h1></div>
        <div id="gotoDetailsText" class="mui-panel"></div>
    </div>
</div>
        `),
  'showDirectionsPanel': function(toDest, cb = null) {
    console.log('Showing directions', toDest);

    const detail = { 'from':{}, 'to':{} };

    this.$newPanel = createPanel({ 'title':'Directions', 'divId':'directionsP' }, cb);

    this.$el = addPanel(this.$newPanel);

    // this.$el.empty();
    this.$newPanel.show();

    const l = this.location.toJSON();

    console.log('l', l);

    if (l.hasOwnProperty('latitude')) {
      detail.from.lat = l.latitude;
      detail.from.long = l.longitude;
    }
    else
      console.log('>> No location yet');

    detail.to.lat = toDest.latitude;
    detail.to.long = toDest.longitude;

    console.log('detail', detail);

    this.model.set('detail', detail);
    // if (prevGuid === guid)
    // this.doRender();
  },
  'events': {
    'click .closebutton': 'doClick'
  },
  'doClick': function(d) {
    console.log('Do click', d);
    const id = get(d, 'currentTarget', '');
    console.log(id);
    // this.eventBus.trigger('showNews', id);
  },
  'doClose': function(d) {
    this.model.clear({ 'silent':true });
    console.log('close??');
  },
  'doRender': function() {
    this.$el.empty();
    //   this.$el.html(this.template(this.model.get('article')));
    this.$el.html(this.template());

    const html = templates.templateDirections({ 'directions':this.model.get('directions') });
    console.log(html);
    const $el = this.$el;

    $el.find('#gotoDetailsText').html(html);
  },
  'doUpdateRender': function() {
    // this.$el.empty();
    //   this.$el.html(this.template(this.model.get('article')));



  }

});

module.exports = { GotoCardModel, GotoCardView };
